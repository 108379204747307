$hyoo_lamps $mol_book2
	plugins /
		<= Theme $mol_theme_auto
	lamp_current_id?val \
	pages /
		<= Addon_page $mol_page
			title \LampTest.ru
			body_scroll_top?val <=> menu_scroll_top?val 0
			tools /
				<= Source $mol_link_source uri \https://github.com/hyoo-ru/lamps.hyoo.ru
				<= Lights $mol_lights_toggle
			body /
				<= Menu $mol_list
					rows <= lamp_rows /
						<= Filter $mol_search
							hint <= filter_hint @ \Filter...
							query?val <=> filter?val \
						<= Lamp_row*0 $mol_link
							sub / <= Lamp_row_dimmer* $mol_dimmer
								needle <= filter
								haystack <= lamp_title* \
							arg <= lamp_arg* *
		<= Main_page*0 $mol_page
			title <= title \
			tools /
				<= Close $mol_link
					sub /
						<= Close_icon $mol_icon_cross
					arg *
						lamp null
			body /
				<= Info $mol_row
					sub /
						<= Stat $mol_view
							sub /
								<= Rating $mol_labeler
									title <= rating_title @ \Rating
									content /
										<= rating 0
						<= Props $mol_view
							sub /
								<= Wattage $mol_labeler
									title <= wattage_title @ \Wattage
									content /
										<= wattage \
						<= Body $mol_view
							sub /
								<= Type $mol_labeler
									title <= type_title @ \Type
									content /
										<= type \
								<= Shape $mol_labeler
									title <= shape_title @ \Shape
									content /
										<= shape \
								<= Base $mol_labeler
									title <= base_title @ \Base
									content /
										<= base \
						<= Light $mol_view
							sub /
								<= Temp $mol_labeler
									title <= Temp_title @ \Temperature
									content /
										<= temp \
								<= Cri $mol_labeler
									title <= cri_title @ \CRI
									content /
										<= cri \
								<= Ripple $mol_labeler
									title <= ripple_title @ \Ripple
									content /
										<= ripple \
								<= Angle $mol_labeler
									title <= angle_title @ \Angle
									content /
										<= angle \
				<= Gallery $mol_view
					sub /
						<= Photo $mol_image
							uri <= photo \
							title <= title
